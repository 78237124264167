import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Offcanvas,
  Modal,
  Nav,
  Navbar,
  Dropdown,
} from "react-bootstrap";
import { logoutUser } from "../redux/auth/actions";
import { CasinoProviders } from "../lib/data";
import { getUserBet } from "../redux/sports/actions";
import { getNotifications } from "../redux/app/actions";
import { AiOutlineClose } from "react-icons/ai";
import { VscVerifiedFilled } from "react-icons/vsc";
import logo from "../assets/images/logo.webp";

import numberWithCommas from "../utils/numbersWithComma";
import Profile from "../assets/images/icons/user-profile-wht.png";
import Withdrawal from "../assets/images/icons/withdrawal-wht.png";
import UserMenu from "../assets/images/icons/home-page/user-menu.png";
import MyBets from "../assets/images/icons/my-bets-wht.png";
import Bonus from "../assets/images/icons/bonus-wht.png";
import Referral from "../assets/images/icons/referral-wht.png";
import Statement from "../assets/images/icons/statement-wht.png";
import SPMGames from "../assets/images/icons/spm-games.png";
import Logout from "../assets/images/icons/signout-wht.png";
import Sports from "../assets/images/games-icon/headicon/sports.png";
import Exchange from "../assets/images/games-icon/headicon/exchange.png";
import Sportsbook from "../assets/images/games-icon/headicon/sportsbook.png";
import Evolution from "../assets/images/games-icon/headicon/evolution.png";
import Ezugi from "../assets/images/games-icon/headicon/ezugi.png";
import Slots from "../assets/images/games-icon/headicon/slots.png";
import Kingmaker from "../assets/images/games-icon/headicon/kingmaker.png";
import Aviator from "../assets/images/games-icon/Aviator.png";

import AddFund from "../assets/images/icons/home-page/add-fund.png";

const HeaderAfterLogin = () => {
  const { user, wallet, exposureAmount } = useSelector((state) => state.auth);
  const { betMatches, userBets } = useSelector((state) => state.sports);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const [showBetModal, setShowBetModal] = useState(false);
  const [showUserBetModal, setShowUserBetModal] = useState(false);
  const [websiteNotifications, setWebsiteNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(true);

  useEffect(() => {
    loadNotifications();
    return () => {};
  }, []);

  const loadNotifications = async () => {
    // get notifications
    const notifications = await getNotifications();
    if (notifications && notifications?.data?.data) {
      setShowNotifications(true);
      setWebsiteNotifications(notifications?.data?.data);
    }
  };

  const handleRowClick = (item) => {
    dispatch(
      getUserBet({
        skip: 0,
        pageSize: 10,
        refmatchid: item?.refMatchId,
      })
    );
    setShowBetModal(false);
    setShowUserBetModal(true);
  };

  return (
    <>
      {(websiteNotifications.length && showNotifications && (
        <div className="marqu">
          <marquee>
            {websiteNotifications.map((notf, idx) => (
              <label style={{ margLKRight: "30px" }} key={idx}>
                {notf?.content}
              </label>
            ))}
          </marquee>
          <span onClick={() => setShowNotifications(false)}>
            <AiOutlineClose />
          </span>
        </div>
      )) ||
        null}

      <header className="header aftrlgn">
        <div className="d-flex align-items-center h-100">
          <div className="logo">
            <a href="/home">
              <img src={appDetails?.LOGO_URL} alt="Logo" />
              {/* <img src={logo} alt="Logo" /> */}
            </a>
          </div>

          <div className="headerRight ms-auto">
            <div className="bonus-head">
              <a href="/bonus">
                <span>Bonus</span>
                <div className="notification"></div>
              </a>
            </div>

            <div className="funds">
              <Dropdown align="end">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  ₹ <span>{numberWithCommas(wallet?.cash || 0)}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="tel:{user?.mobileno}">
                    <b>{user?.mobileno}</b>
                  </Dropdown.Item>
                  <Dropdown.Item href="#">
                    <h6>Balance</h6>
                    <span>INR {numberWithCommas(wallet?.balance || 0)}</span>
                  </Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => setShowBetModal(true)}>
                    <h6>Cash</h6>
                    <span>INR {numberWithCommas(wallet?.cash || 0)}</span>
                  </Dropdown.Item>
                  {/* <Dropdown.Item href="#" onClick={() => setShowBetModal(true)}>
                    <h6>Exposure</h6>
                    <span>INR {numberWithCommas(exposureAmount || 0)}</span>
                  </Dropdown.Item> */}
                  <Dropdown.Item href="/bonus">
                    <h6>Bonus</h6>
                    <span>INR {numberWithCommas(wallet?.coins)}</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <a href="/gatewaylist" className="deposit">
                <img src={AddFund} alt="add fund" />
              </a>
            </div>

            <Button variant="primary menu-btn" onClick={() => setShow(true)}>
              <img src={UserMenu} alt="my menu" style={{ width: "16px" }} />
              <div className="notification"></div>
            </Button>
          </div>
        </div>

        {/* <div className="bottom_head">
          <Navbar expand="md" className="d-none d-md-block">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav>
                <Nav.Link href="/home" className="logoImg d-md-none">
                  <img src={appDetails?.LOGO_URL} alt="Logo" />
                </Nav.Link>
                <Nav.Link href="/spmsports/cricket">
                  <img src={Sports} />
                  Sports
                </Nav.Link>
                <Nav.Link href="/Exchange/cricket">
                  <img src={Exchange} />
                  Exchange
                </Nav.Link>
                <Nav.Link href="/betby" className="">
                  <img src={Sportsbook} />
                  Sportsbook
                </Nav.Link>
                <Nav.Link href="/sportsbook2" className="">
                  <img src={Sportsbook} />
                  Sportsbook 2
                </Nav.Link>
                <Nav.Link href={CasinoProviders["evolution"]?.href}>
                  <img src={Evolution} />
                  Evolution
                </Nav.Link>
                <Nav.Link href={CasinoProviders["ezugi"]?.href}>
                  <img src={Ezugi} />
                  Ezugi
                </Nav.Link>
                <Nav.Link href="/slots">
                  <img src={Slots} />
                  Slots
                </Nav.Link>
                <Nav.Link href="/kingmaker">
                  <img src={Kingmaker} />
                  Kingmaker
                </Nav.Link>
                <Nav.Link href="/casino/spribe/aviator">
                  <img src={Aviator} />
                  Aviaitor
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div> */}

        <Offcanvas
          className="user-menu"
          show={show}
          onHide={() => setShow(false)}
          placement={"end"}
        >
          <Offcanvas.Header closeButton="closeButton" closeVariant="white">
            <Offcanvas.Title>User Profile</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="user-info">
              <div className="details">
                <div className="profile-img">
                  <img src={Profile} alt="profile" style={{ width: "24px" }} />
                </div>
                <div className="information">
                  <h6>{user?.mstruserid}</h6>
                  <span>{user?.mobileno}</span>
                </div>
              </div>

              <div className="bal-dpst">
                <div className="bal">
                  ₹ {numberWithCommas(wallet?.balance || 0)}
                </div>
                <a href="/gatewaylist" className="dpst">
                  Deposit
                </a>
              </div>
            </div>

            <div className="menu-list">
              <ul className="menu-items">
                <li>
                  <a href="/profile">
                    <img src={Profile} alt="profile" />
                    <span>Profile</span>
                  </a>
                </li>
                <li>
                  <a href="/withdraw">
                    <img src={Withdrawal} alt="withdrawal" />
                    <span>Withdrawal</span>
                  </a>
                </li>
                <li>
                  <a href="/mybets">
                    <img src={MyBets} alt="my bets" />
                    <span>My Bets</span>
                  </a>
                </li>
                <li>
                  <a href="/bonus">
                    <img src={Bonus} alt="bonus" />
                    <span>Bonus</span>
                  </a>
                </li>
                <li>
                  <a href="/referral">
                    <img src={Referral} alt="referral" />

                    <span>Referral</span>
                  </a>
                </li>
                <li>
                  <a href="/cashier">
                    <img src={Statement} alt="account statement" />
                    <span>Account Statement</span>
                  </a>
                </li>
                {/* <li>
                  <a href="#">
                    <SettingIcon />
                    <span>Transfer Funds ( For Excahnge)</span>
                  </a>
                </li> */}
                {/* <li>
                  <a href="/settings">
                    <SettingIcon />
                    <span>Settings</span>
                  </a>
                </li> */}
                {/* <li>
                  <a href="/spmgames">
                    <img src={SPMGames} alt="SPM games" />
                    <span>SPM Games</span>
                  </a>
                </li> */}
                {/* <li>
                  <a href="/kyc">
                    <VscVerifiedFilled />
                    <span>KYC</span>
                  </a>
                </li> */}
                <li onClick={() => dispatch(logoutUser())}>
                  <a href="/">
                    <img src={Logout} alt="logout" />
                    <span>Sign Out</span>
                  </a>
                </li>
              </ul>
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        <Modal
          className="betslip_popup outer_popup"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showBetModal}
        >
          <Modal.Body>
            <main className="main">
              <div className="container table-responsive">
                <table className="table text-white">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Exposure amount</th>
                      <th scope="col">Match name</th>
                      <th scope="col">Ref match ID</th>
                      <th scope="col">Total bets</th>
                    </tr>
                  </thead>
                  <tbody>
                    {betMatches &&
                      betMatches.length &&
                      betMatches.map((item, index) => {
                        return (
                          <tr
                            onClick={() => {
                              handleRowClick(item);
                            }}
                          >
                            <th scope="row">{index + 1}</th>
                            <td>{item?.exposureAmount}</td>
                            <td>{item?.matchName}</td>
                            <td>{item?.refMatchId}</td>
                            <td>{item?.totalBets}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </main>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowBetModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          className="betslip_popup inner_popup"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showUserBetModal}
        >
          <Modal.Body>
            {" "}
            <main className="main">
              <div className="container table-responsive">
                <table className="table text-white">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Odds</th>
                      <th scope="col">Stack</th>
                      <th scope="col">Selection type</th>
                      <th scope="col">Status</th>
                      <th scope="col">Create date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userBets &&
                      userBets.length &&
                      userBets.map((item, index) => {
                        return (
                          <tr>
                            <th scope="row">{index + 1}</th>
                            <td>{item?.odds}</td>
                            <td>{item?.stack}</td>
                            <td>{item?.selectionType}</td>
                            <td>{item?.status}</td>
                            <td>{item?.createdAt}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </main>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowUserBetModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      </header>
    </>
  );
};

export default HeaderAfterLogin;
