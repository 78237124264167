import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/dist";

import Telegram from "../assets/images/icons/home-page/leftbar/Telegram.webp";
import Instagram from "../assets/images/icons/home-page/leftbar/Instagram.webp";
import YouTube from "../assets/images/icons/home-page/leftbar/YouTube.webp";
import Facebook from "../assets/images/icons/home-page/leftbar/Facebook.webp";
import XTwitter from "../assets/images/icons/home-page/leftbar/XTwitter.webp";
import Whatsapp from "../assets/images/Whatsapp.svg";
import LiveSupport from "../assets/images/icons/home-page/leftbar/LiveSupport.webp";
import PayTM from "../assets/images/icons/home-page/footer/PayTM.svg";
import UPI from "../assets/images/icons/home-page/footer/UPI.svg";
import IMPS from "../assets/images/icons/home-page/footer/IMPS.svg";
import INB from "../assets/images/icons/home-page/footer/INB.svg";
import RuPay from "../assets/images/icons/home-page/footer/RuPay.svg";
import Mobikwik from "../assets/images/icons/home-page/footer/Mobikwik.svg";
import Visa from "../assets/images/icons/home-page/footer/Visa.svg";
import Bank from "../assets/images/icons/home-page/footer/Bank.svg";
import Mastercard from "../assets/images/icons/home-page/footer/Mastercard.svg";
import Olamoney from "../assets/images/icons/home-page/footer/Olamoney.svg";
import PhonePe from "../assets/images/icons/home-page/footer/PhonePe.svg";
import Airtel from "../assets/images/icons/home-page/footer/Airtel.svg";
import JioMoney from "../assets/images/icons/home-page/footer/JioMoney.svg";
import Freecharge from "../assets/images/icons/home-page/footer/Freecharge.svg";
import Astropay from "../assets/images/icons/home-page/footer/Astropay.svg";
import ETH from "../assets/images/icons/home-page/footer/ETH.svg";
import USDT from "../assets/images/icons/home-page/footer/USDT.svg";
import XRP from "../assets/images/icons/home-page/footer/XRP.svg";
import LTC from "../assets/images/icons/home-page/footer/LTC.svg";
import DOGE from "../assets/images/icons/home-page/footer/DOGE.svg";
import BTC from "../assets/images/icons/home-page/footer/BTC.svg";
import LicOrange from "../assets/images/icons/home-page/footer/lic_orange.png";
import Age18 from "../assets/images/icons/home-page/footer/age18.png";
import BottomNav from "./BottomNav";
import TawkChat from "../views/components/TawkChat";

const Footer = () => {
  const { appDetails } = useSelector((state) => state.app);
  const tawkMessengerRef = useRef();
  const handleSupportClick = () => {
    tawkMessengerRef.current?.toggle();
  };
  return (
    <footer className="footer">
      <div className="inner-content">
        <div className="social-contact">
          <p>Follow us and catch up on the latest news!</p>
          <div className="social-type">
            <a
              href={appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"}
              target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
            >
              <img src={Telegram} alt="telegram" />
            </a>
            <a
              href={appDetails?.INSTATGRAM != "" ? appDetails?.INSTATGRAM : "#"}
              target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
            >
              <img src={Instagram} alt="instagram" />
            </a>
            <a href="javascript:void(0)">
              <img src={YouTube} alt="youtube" />
            </a>
            <a
              href={appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"}
              target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
            >
              <img src={Facebook} alt="facebook" />
            </a>
            <a href="javascript:void(0)">
              <img src={XTwitter} alt="twitter" className="tw" />
            </a>
            {/* <a
              className="panel"
              href={
                appDetails?.WHATS_APP_URL != ""
                  ? appDetails?.WHATS_APP_URL
                  : "#"
              }
              target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
            >
              <img src={Whatsapp} alt="whatsapp" />
            </a> */}
          </div>
        </div>

        <div className="foot-links">
          <div className="links">
            <div className="link-box">
              <a href="/contactus">Contact Us</a>
              <a href="/privacypolicy ">Privacy Policy</a>
              <a href="/responsiblegame">Responsible Gaming</a>
            </div>
            <div className="link-box">
              <a href="/fairplay">Fair Play</a>
              <a href="/gamesrule">Games Rules</a>
              <a href="/terms">Terms and Conditions</a>
            </div>
          </div>
          <div className="support">
            <a href="javascript:void(0)" onClick={handleSupportClick}>
              <img src={LiveSupport} alt="live support" />
              <span>Live Support</span>
            </a>
          </div>
        </div>

        <div className="payment-methods">
          <div className="img-box">
            <img src={PayTM} alt="PayTM" />
          </div>
          <div className="img-box">
            <img src={UPI} alt="UPI" />
          </div>
          <div className="img-box">
            <img src={IMPS} alt="IMPS" />
          </div>
          <div className="img-box">
            <img src={INB} alt="INB" />
          </div>
          <div className="img-box">
            <img src={RuPay} alt="RuPay" />
          </div>
          <div className="img-box">
            <img src={Mobikwik} alt="Mobikwik" />
          </div>
          <div className="img-box">
            <img src={Visa} alt="Visa" />
          </div>
          <div className="img-box">
            <img src={Bank} alt="Bank" />
          </div>
          <div className="img-box">
            <img src={Mastercard} alt="Mastercard" />
          </div>
          <div className="img-box">
            <img src={Olamoney} alt="Olamoney" />
          </div>
          <div className="img-box">
            <img src={PhonePe} alt="PhonePe" />
          </div>
          <div className="img-box">
            <img src={Airtel} alt="Airtel" />
          </div>
          <div className="img-box">
            <img src={JioMoney} alt="JioMoney" />
          </div>
          <div className="img-box">
            <img src={Freecharge} alt="Freecharge" />
          </div>
          <div className="img-box">
            <img src={Astropay} alt="Astropay" />
          </div>
          <div className="img-box">
            <img src={ETH} alt="ETH" />
          </div>
          <div className="img-box">
            <img src={USDT} alt="USDT" />
          </div>
          <div className="img-box">
            <img src={XRP} alt="XRP" />
          </div>
          <div className="img-box">
            <img src={LTC} alt="LTC" />
          </div>
          <div className="img-box">
            <img src={DOGE} alt="DOGE" />
          </div>
          <div className="img-box">
            <img src={BTC} alt="BTC" />
          </div>
        </div>

        <div className="disclaimer-text d-none">
          <p>
            <a href="pari96.ocm">Pari 96</a> is owned and operated by Breakout
            Group B.V. (registration number: ******, registration address:
            ______ ___ _____ __________ **, *__ _____, _._. ___ ****, _______).
            Pari96 is licensed and regulated by the Government of _______ under
            the gaming licence ****/___. Payment processing services are partly
            provided by __ ________ ____ ___ (company number: __ ******,
            address: Office ***, ______ __________, *, ****, ________, ______).
            Contact us:
            <a href="mailto:support@pari96.com">support@pari96.com</a>
          </p>
          <p>
            In order to register for this website, the user is required to
            accept the General Terms and Conditions. In the event the General
            Terms and Conditions are updated, existing users may choose to
            discontinue using the products and services before the said update
            shall become effective, which is a minimum of two weeks after it has
            been announced.
          </p>
        </div>

        <div className="copyright-bar">
          <div className="left-content">
            <img src={LicOrange} alt="lic orange" className="lic" />
            <img src={Age18} alt="age 18 +" className="age18" />
            <span>1 BTC ≈ $86,166.01</span>
          </div>
          <div className="right-content">
            <span>© 2024 Pari96. All rights reserved.</span>
          </div>
        </div>

        {/* <p>{appDetails?.FOOTER_TEXT || ""}</p> */}
      </div>

      <BottomNav />
      <TawkChat ref={tawkMessengerRef} />
    </footer>
  );
};

export default Footer;
